import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';
import { commonHeaderColor } from '../../../util';


const Page = ({ data }) => (
  <>
    <SEO
      title="Trouvez un point de vente rapidement et facilement"
      description="Tout le monde peut accéder à ce mode de paiement sécurisé en se rendant dans un magasin de proximité, un supermarché, une station-service ou d'autres revendeurs Neosurf de confiance."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf/point-of-sale',
          language: 'en'
        },
        {
          path: 'acheter-neosurf/point-de-vente',
          language: 'fr'
        },
        {
          path: 'buy-neosurf/verkaufsstelle',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Trouvez un point de vente</h1>
            <p className="textColorDark">Localisez votre point de vente Neosurf en toute simplicité !</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Vous recherchez un bon Neosurf que vous préférez acheter en personne ? Trouver un point de vente est rapide et simple, car il existe des milliers de lieux où vous pouvez obtenir des bons Neosurf. Tout le monde peut accéder à ce mode de paiement sécurisé en se rendant dans un magasin de proximité, un supermarché, une station-service ou d'autres revendeurs Neosurf de confiance.</p>
      <p className="textColorDark">La première étape pour trouver un point de vente consiste à utiliser l'outil de localisation en ligne disponible sur le site Neosurf. Cet outil vous permet de rechercher des vendeurs agréés en saisissant votre ville, votre code postal ou votre position actuelle. En quelques secondes, vous obtenez une liste des vendeurs à proximité où vous pouvez acheter des bons Neosurf. L'outil de localisation fournit également des informations supplémentaires telles que les horaires d'ouverture et les coordonnées, ce qui facilite encore davantage l'obtention des bons.</p>
      <p className="textColorDark">Pour ceux qui voyagent fréquemment et préfèrent les achats physiques, avoir accès à un point de vente Neosurf près de chez soi est extrêmement pratique. Grâce à la large diffusion de Neosurf, il est très probable que vous trouviez un revendeur Neosurf à proximité, que vous soyez chez vous ou dans une nouvelle ville. Pour obtenir un bon, il vous suffit d'entrer dans un magasin, de demander un bon Neosurf avec le montant souhaité et d'effectuer votre achat en espèces ou par carte.</p>
      <p className="textColorDark">L'achat en personne présente un grand avantage pour ceux qui accordent de l'importance à la confidentialité et à la sécurité. Avec Neosurf, il n'est pas nécessaire de fournir des informations personnelles ou financières lors de l'achat. Cela en fait une option idéale pour ceux qui ont des préoccupations en matière de confidentialité en ligne.</p>
      <p className="textColorDark">Après avoir acheté un bon Neosurf, vous recevrez un reçu imprimé contenant un code unique. Vous utiliserez ce code pour effectuer des paiements en ligne auprès des plateformes qui acceptent les bons Neosurf. L'utilisation du code est très simple : il vous suffit de le saisir lors du passage en caisse après avoir sélectionné Neosurf comme méthode de paiement, et la transaction sera traitée instantanément. Que vous rechargiez votre compte de jeu, fassiez des achats en ligne ou souscriviez à un service, Neosurf rend cela incroyablement simple et, surtout, sécurisé.</p>
      <p className="textColorDark">Les points de vente Neosurf se multiplient constamment, ce qui vous permet de trouver facilement un revendeur près de chez vous. En choisissant un vendeur physique de confiance, vous bénéficiez de la tranquillité d'esprit qu'offre une méthode de paiement reconnue mondialement. Prêt à acheter un bon ? Utilisez le localisateur de points de vente Neosurf pour trouver le point de vente le plus proche et commencez à profiter des incroyables avantages de Neosurf !</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
